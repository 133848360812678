import React from "react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import Metrics from "../../components/metrics/metrics.component";
import axios from "axios";
import Filters from "../../components/filters/filters.component";

import {
  bigCardFirstSlider,
  bigCardSecondSlider,
  bigCardThirdSlider,
} from "../../utils/fakeData";
import TrafficLight from "../../components/traffic-light/traffic-light.component";
import { getUserInfo } from "../../services/userInfo.service";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const HomeBody = () => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();

  const [runTutorial, setRunTutorial] = useState(false);

  const [metricsData, setMetricsData] = useState({});

  const [period, setPeriod] = useState("w");

  const [subtitle, setSubtitle] = useState("");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `https://data.myma.space/api/metrics?clientId=${encodeURIComponent(userInfo.customer_id)}&group=${encodeURIComponent(filters.groups)}&period=${period}&storeClusterId=${encodeURIComponent(filters.clusters)}&location=${encodeURIComponent(filters.locations)}&category=${encodeURIComponent(filters.categories)}`
        );
        setMetricsData(data);
      }
    } catch (error) {
      // Error handler
      console.error("Error 500 en la solicitud de métricas:", error);
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [period, filters, loginToken]
  );

  useEffect(() => {
    // Filter properties which aren't "default" or "all"
    const filteredProperties = Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== "default" && value !== "all" && key !== "machines"
      )
      .map(([key, value]) => value);

    // Build subtitle with filtered properties
    const newSubtitle = filteredProperties.join(" ");

    // Update subtitle state
    setSubtitle(newSubtitle || null); // If newSubtitle is empty , set it to null
  }, [filters]);

  const sliderSettings = {
    spaceBetween: -15,
    slidesPerView: 1.06,
  };

  return (
    <PullToRefreshContainer onRefresh={fetchMetrics}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mi</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Portfolio</div>
          </div>
        }
        setRunTutorial={setRunTutorial}
        runTutorial={runTutorial}
        avatar="FB"
      />
      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Filters
          filters={filters}
          setFilters={setFilters}
          machinesSelectorDisabled
        />
        <Swiper {...sliderSettings}>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={{
                ...bigCardFirstSlider,
                subtitle: subtitle,
              }}
              metricsData={metricsData}
              logo="trendlinechart"
              slideIndex="0"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={{ ...bigCardSecondSlider, subtitle: subtitle }}
              metricsData={metricsData}
              logo="shopcart"
              slideIndex="1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={{ ...bigCardThirdSlider, subtitle: subtitle }}
              metricsData={metricsData}
              logo="trendlinechart"
              slideIndex="2"
            />
          </SwiperSlide>
        </Swiper>
        <Section title="KPIS Relevantes" />
        <Swiper {...sliderSettings}>
          <SwiperSlide>
            <Metrics slideIndex="0" metricsData={metricsData} />
          </SwiperSlide>
          <SwiperSlide>
            <Metrics slideIndex="1" metricsData={metricsData} />
          </SwiperSlide>
        </Swiper>
        <Section title="Rendimiento Semanal" header="b" />
        <div style={{ marginBlockEnd: "0.5rem" }}>
          {metricsData?.positions?.map((element, id) => {
            return <TrafficLight key={id} {...element} />;
          })}
        </div>
      </Box>
      <Footer activeIconID={0} />
    </PullToRefreshContainer>
  );
};
const Home = () => {
  return (
    <>
      <HomeBody />
    </>
  );
};

export default Home;
