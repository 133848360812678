import React, { useState } from "react";
import {
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import InProgressButton from "./buttons/in-progress-button.component";
import StartButton from "./buttons/start-button.component";
import MiddleSectionAction from "./middle-section/action.component";
import MiddleSectionProblem from "./middle-section/problem.component";
import axios from "axios";
import { getUserInfo } from "../../../services/userInfo.service";
import { experimentsEquivalency } from "../experimentsEquivalency";
import IncomingButton from "./buttons/incoming-button.component";

const PendingExperimentsCard = ({
  fetchMetrics,
  status,
  experimentType,
  product,
  store,
  period,
  problem,
  id,
  incoming,
}) => {
  const userInfo = getUserInfo();

  //Experiment middle section selector - dev start
  const [experimentMiddleSection, setExperimentMiddleSection] =
    useState("problem");
  const handleMiddleSection = (event, newSection) => {
    if (newSection === null) {
      return;
    }

    setExperimentMiddleSection(newSection);
  };
  //Experiment middle section selector - dev end

  const formatNumber = (num) => {
    return `[${num.toString().padStart(4, "0")}]`;
  };

  const handleExperiment = (experimentId) => {
    const apiUrl = `https://data.myma.space/api/experiments/${experimentId}/start?clientId=${encodeURIComponent(userInfo.customer_id)}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (userInfo) {
      axios
        .post(apiUrl, {}, config)
        .then((response) => {
          console.log("Experiment started successfully:", response.data);
          fetchMetrics();
        })
        .catch((error) => {
          console.error("Error starting experiment:", error);
        });
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          display: "flex",
          width: "350px",
          height: "12rem",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            // border: "2px solid #727272",
            borderRadius: "1rem",
            paddingInlineEnd: "0.5rem",
            paddingInlineStart: "0.2rem",
            paddingBlock: "0.5rem",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* header */}
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "1rem",
              maxHeight: 1 / 6,
            }}
            item
            xs={12}
          >
            <Grid item sx={{ display: "flex", height: "100%" }}>
              {/* <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={1}
            ></Grid> */}
              <Grid item sx={{ display: "flex", alignItems: "center" }} xs={12}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  EXPERIMENTO
                </Typography>
                &nbsp;
                <Typography
                  variant="body2"
                  sx={{ color: "#EA572E", fontWeight: "bold" }}
                >
                  {formatNumber(id)}
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #EA572E",
                    color: "#EA572E",
                    borderRadius: "5px",
                    padding: "2px 7px",
                    fontSize: "0.5rem",
                    marginInlineStart: "0.5rem",
                  }}
                >
                  {experimentsEquivalency?.[experimentType?.name]?.shortLabel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* header end  */}

          {/* mid section start  */}
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "1rem",
              maxHeight: 3.5 / 6,
            }}
            item
            xs={12}
          >
            {/* Middle section selector button  */}
            <Grid item sx={{ height: 1 / 4, display: "flex" }}>
              {/* <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={1}
            ></Grid> */}
              <Grid
                sx={{
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                item
                xs={12}
              >
                <ToggleButtonGroup
                  // color="primary"
                  value={experimentMiddleSection}
                  exclusive
                  onChange={handleMiddleSection}
                  aria-label="Platform"
                  size="small"
                  fullWidth
                  sx={{
                    height: "1.2rem",
                    "& .Mui-selected": { backgroundColor: "#e6e6e6" },
                  }}
                >
                  <ToggleButton sx={{ borderRadius: "5px" }} value="problem">
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          experimentMiddleSection === "problem"
                            ? "#EA572E"
                            : "#bfbfbf",
                        fontWeight: "bold",
                      }}
                    >
                      PROBLEMA
                    </Typography>
                  </ToggleButton>
                  <ToggleButton sx={{ borderRadius: "5px" }} value="action">
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          experimentMiddleSection === "action"
                            ? "#EA572E"
                            : "#bfbfbf",
                        fontWeight: "bold",
                      }}
                    >
                      ACCIÓN
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            {/* Middle section selector button  */}
            {experimentMiddleSection === "problem" ? (
              <MiddleSectionProblem
                problem={problem}
                product={product}
                store={store}
              />
            ) : (
              <MiddleSectionAction
                experimentType={experimentType}
                period={period}
                product={product}
                status={status}
              />
            )}
            {/* mid section end  */}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "1rem",
              maxHeight: 1.5 / 6,
            }}
            item
            xs={12}
          >
            <Grid item sx={{ display: "flex", height: "100%" }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                xs={1}
              ></Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
                xs={11}
              >
                {(() => {
                  if (!incoming) {
                    switch (status.status) {
                      case "running":
                        return <InProgressButton />;
                      case "pending":
                        return (
                          <StartButton onClick={() => handleExperiment(id)} />
                        );
                      default:
                        return null;
                    }
                  } else if (incoming) {
                    return <IncomingButton />;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PendingExperimentsCard;
